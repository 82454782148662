import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import { isSuperAdmin, isUserHasPermission } from "../../Utils/Permissions";

const ProtectedRoute = ({
  component: Component,
  permission,
  requiredRoles,
}) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const permissionArr = permission
    ? permission.map((elt) => isUserHasPermission(elt))
    : [];


  if (permissionArr.some((elt) => elt === true) || isSuperAdmin()) {
    return (
      <>
        <Navbar />
        <Component />
      </>
    );
  } else {
    return (
      <>
        <Navigate to="/noPermissions" />;
      </>
    );
  }
};

export default ProtectedRoute;
