import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  allRoles: [],
  allPermissions: null,
  singleRole: null,
};

export const getAllRoles = createAsyncThunk("roles/getAllRoles", async () => {
  return await axiosInstance
    .get("Roles/AllRolesandPermissions")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      toast.error(error.response.data.error.message);
      return error;
    });
});
export const getSingleRole = createAsyncThunk(
  `roles/getSingleRole`,
  async (id) => {
    return await axiosInstance
      .get(`Roles/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const addRole = createAsyncThunk(
  "roles/addRole",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Roles/AddRolesWithPermissions", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(
          error.response.data.error.message
            ? error.response.data.error.message
            : "Something Wrong"
        );

        return error;
      });
  }
);
export const updateRole = createAsyncThunk(
  "roles/updateRole",
  async (data, { dispatch }) => {
    return await axiosInstance
      .put("Roles/UpdateRolesWithPermissions", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(
          error.response.data.error.message
            ? error.response.data.error.message
            : "Something Wrong"
        );

        return error;
      });
  }
);
export const getAllPermissions = createAsyncThunk(
  "roles/getAllPermissions",
  async () => {
    return await axiosInstance
      .get("Roles/GetCategoriesWithPermissions")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

const RolesSlice = createSlice({
  name: "roles",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.allRoles = action.payload.data;
    });
    builder.addCase(getSingleRole.fulfilled, (state, action) => {
      state.allPermissions = action.payload.data.data;
      state.singleRole = action.payload.data.data;
    });
    builder.addCase(getAllPermissions.fulfilled, (state, action) => {
      state.allPermissions = action.payload.data.data;
    });
  },
});

export const RolesReducer = RolesSlice.reducer;
