import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as LazyComponent from "./LazyLoading";
import Loader from "../Components/Loader/Loader";
import ProtectedRoutes from "../Components/ProtectedRoutes/ProtectedRoutes";
// import history from "./History";

const Routess = (
  <>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="login" element={<LazyComponent.Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoutes
              path="/"
              component={LazyComponent.Home}
              permission={[
                "Dashboard_Salary_Grade_Statistics",
                "Dashboard_JD_Statistics",
              ]}
            />
          }
        />
        <Route
          path="salaryGrade"
          element={
            <ProtectedRoutes
              path="salaryGrade"
              component={LazyComponent.AddNewSalaryGrade}
              permission={["All_Salary_Grade"]}
            />
          }
        />
        <Route
          path="searchSalaryGrade"
          element={
            <ProtectedRoutes
              path="searchSalaryGrade"
              component={LazyComponent.SearchSalaryGrade}
              permission={["Search_Salary_Grade"]}
            />
          }
        />
        <Route
          path="allSalaryGrade"
          element={
            <ProtectedRoutes
              path="allSalaryGrade"
              component={LazyComponent.AllSalaryGrade}
              permission={["All_Salary_Grade"]}
            />
          }
        />
        <Route
          path="allTemplates"
          element={
            <ProtectedRoutes
              path="allTemplates"
              component={LazyComponent.AllTemplates}
              permission={["Template_List"]}
            />
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoutes
              path="profile"
              component={LazyComponent.Profile}
              permission={["CONSULTANT", "SUPER_ADMIN", "ENDUSER"]}
            />
          }
        />
        <Route
          path="jobdescriptions"
          element={
            <ProtectedRoutes
              path="jobdescriptions"
              component={LazyComponent.JobDescriptions}
              permission={["Add_Job_Description"]}
            />
          }
        />
        <Route
          path="jobDescriptionApproval"
          element={
            <ProtectedRoutes
              path="jobDescriptionApproval"
              component={LazyComponent.AllJobDescription}
              permission={["Approve_Job_Description"]}
            />
          }
        />{" "}
        <Route
          path="userJobDescription"
          element={
            <ProtectedRoutes
              path="userJobDescription"
              component={LazyComponent.UserJobDescription}
              permission={["Approve_Job_Description"]}
            />
          }
        />
        <Route
          path="projects"
          element={
            <ProtectedRoutes
              path="projects"
              component={LazyComponent.Projects}
              permission={["Add_New_Projects"]}
            />
          }
        />
        <Route
          path="addProject"
          element={
            <ProtectedRoutes
              path="addProject"
              component={LazyComponent.AddProject}
              permission={["Add_New_Projects"]}
            />
          }
        />
        <Route
          path="editProject/:id"
          element={
            <ProtectedRoutes
              path="editProject/:id"
              component={LazyComponent.AddProject}
              permission={["Add_New_Projects"]}
            />
          }
        />
        <Route
          path="agentList"
          element={
            <ProtectedRoutes
              path="agentList"
              component={LazyComponent.AgentsList}
              permission={["Agents_List"]}
            />
          }
        />
        <Route
          path="addAgent"
          element={
            <ProtectedRoutes
              path="addAgent"
              component={LazyComponent.AddAgent}
              permission={["AddAgent"]}
            />
          }
        />
        <Route
          path="editAgent/:id"
          element={
            <ProtectedRoutes
              path="editAgent/:id"
              component={LazyComponent.AddAgent}
              permission={["AddAgent"]}
            />
          }
        />
        <Route
          path="addTemplate"
          element={
            <ProtectedRoutes
              path="addTemplate"
              component={LazyComponent.AddTemplate}
              permission={["Add_Template"]}
            />
          }
        />
        <Route
          path="editTemplate/:id"
          element={
            <ProtectedRoutes
              path="editTemplate/:id"
              component={LazyComponent.AddTemplate}
              permission={["Add_Template"]}
            />
          }
        />
        <Route
          path="roleList"
          element={
            <ProtectedRoutes
              path="roleList"
              component={LazyComponent.RoleList}
              permission={["AddAgent"]}
            />
          }
        />
        <Route
          path="addRole"
          element={
            <ProtectedRoutes
              path="addRole"
              component={LazyComponent.AddRole}
              permission={["AddAgent"]}
            />
          }
        />
        <Route
          path="addRole/:id"
          element={
            <ProtectedRoutes
              path="addRole/:id"
              component={LazyComponent.AddRole}
              permission={["AddAgent"]}
            />
          }
        />
        <Route
          path="proposal/:dealId"
          element={
            <ProtectedRoutes
              path="proposal/:dealId"
              component={LazyComponent.Proposal}
              permission={["AddAgent"]}
            />
          }
        />
        <Route
          path="proposallist"
          element={
            <ProtectedRoutes
              path="proposallist"
              component={LazyComponent.ProposalList}
              permission={["AddAgent"]}
            />
          }
        />
        <Route
          path="deals"
          element={
            <ProtectedRoutes
              path="deals"
              component={LazyComponent.Deals}
              permission={["Deals"]}
            />
          }
        />
        <Route path="noPermissions" element={<LazyComponent.NoPermissions />} />
        <Route path="login" element={<LazyComponent.Login />} />
        <Route path="otp" element={<LazyComponent.Otp />} />
      </Routes>
    </Suspense>
  </>
);

export default Routess;
