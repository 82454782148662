// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rtl {
  text-align: right;
  direction: rtl;
}
/* 
.MuiDialog-paper[dir="rtl"] {
  text-align: right;
}

.MuiMenuItem-root[dir="rtl"] {
   text-align: right;
} */
body {
  padding-top: 0rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #114E7A !important;
}
.mui-1jrzfe4-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 600;
  font-size: 1.5rem;
}

table,
th,
td {
  text-align: center !important;
  min-width: fit-content
}
th span svg {
  display: none !important;
}
.bg-custom{
background-color: #114E7A;
}
.text-main{
  color: #114E7A !important;
}
.stepper .active{
  background-color: #114E7A !important;
}
.stepper .completed{
  background-color: rgba(0, 52, 98, 0.9) !important;

}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;AAChB;AACA;;;;;;;GAOG;AACH;EACE,iBAAiB;AACnB;AACA;;;;;;EAME,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;;EAGE,6BAA6B;EAC7B;AACF;AACA;EACE,wBAAwB;AAC1B;AACA;AACA,yBAAyB;AACzB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,oCAAoC;AACtC;AACA;EACE,iDAAiD;;AAEnD","sourcesContent":[".rtl {\n  text-align: right;\n  direction: rtl;\n}\n/* \n.MuiDialog-paper[dir=\"rtl\"] {\n  text-align: right;\n}\n\n.MuiMenuItem-root[dir=\"rtl\"] {\n   text-align: right;\n} */\nbody {\n  padding-top: 0rem;\n}\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  color: #114E7A !important;\n}\n.mui-1jrzfe4-MuiInputBase-root-MuiOutlinedInput-root {\n  font-weight: 600;\n  font-size: 1.5rem;\n}\n\ntable,\nth,\ntd {\n  text-align: center !important;\n  min-width: fit-content\n}\nth span svg {\n  display: none !important;\n}\n.bg-custom{\nbackground-color: #114E7A;\n}\n.text-main{\n  color: #114E7A !important;\n}\n.stepper .active{\n  background-color: #114E7A !important;\n}\n.stepper .completed{\n  background-color: rgba(0, 52, 98, 0.9) !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
