export const PERMISSIONSLIST = {
  Add_NEW_Salary_Grade: 1,
  Search_Salary_Grade: 2,
  All_Salary_Grade: 3,
  Accept_Reject_Salary_Grade: 4,
  AddAgent: 5,
  Agents_List: 6,
  Dashboard_Salary_Grade_Statistics: 7,
  Dashboard_JD_Statistics: 8,
  Add_Job_Description: 9,
  Add_Template: 10,
  Approve_Job_Description: 11,
  Add_New_Projects: 12,
  Template_List: 13,
  Deals: 14,
  Send_Client_Proposal_Request: 15,
  Upload_Proposal_Request: 16,
  Approve_Proposal_Request: 17,
};

export const ConvertPermissions = () => {
  if (localStorage.getItem("token") && localStorage.getItem("permissions")) {
    let permissions = localStorage.getItem("permissions").split(",");
    let PERMISSIONS_ARR = [];
    permissions.map((user_permission) => {
      Object.keys(PERMISSIONSLIST).map(function (key) {
        if (key === user_permission) {
          PERMISSIONS_ARR.push(key);
        }
      });
    });
    return PERMISSIONS_ARR;
  }
};

export const isUserHasPermission = (permission) => {
  let USER_PERMISSIONS = ConvertPermissions();
  return USER_PERMISSIONS ? USER_PERMISSIONS.includes(permission) : null;
};

export const isSuperAdmin = () => {
  // if (localStorage.getItem("token") && localStorage.getItem("roles")) {
  //   let rolesArr = localStorage.getItem("roles").split(",");
  //   let isSuperAdmin = rolesArr.includes("SUPER_ADMIN");
  //   return isSuperAdmin;
  // }
  if (localStorage.getItem("isSuperAdmin") === "true") {
    return true;
  } else return false;
};
