import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  agentsList: "",
  loading: "false",
  singleAgent: null,
};
export const addAgent = createAsyncThunk("agents/addAgent", async (data) => {
  return await axiosInstance
    .post("Auth/register", data)
    .then((response) => {
      toast.success(messages[localStorage.getItem("lang")].shared.done);
      return response;
    })
    .catch((err) => {
      toast.error(err.response.data.error.message);
      return err;
    });
});

export const getAgentsList = createAsyncThunk(
  "agents/getAgentsList",
  async (params) => {
    return await axiosInstance
      .get("Agent/list")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message, {});
        return err;
      });
  }
);

export const updateAgent = createAsyncThunk(
  `agents/updateAgent`,
  async (data, { dispatch }) => {
    return await axiosInstance
      .put(`Agent/UpdateAgent`, data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

export const getSingleAgent = createAsyncThunk(
  `agents/getSingleAgent`,
  async (id) => {
    return await axiosInstance
      .get(`Agent/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

const AgentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAgentsList.fulfilled, (state, action) => {
      state.agentsList = action.payload.data.data;
    });
    builder.addCase(getSingleAgent.fulfilled, (state, action) => {
      state.singleAgent = action.payload.data;
    });
  },
});

export const AgentsReducer = AgentsSlice.reducer;
