import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  proposalId: null,
  basicInformation: null,
  services: [],
  questionsAndAnswers: [],
  proposalSummary: null,
  proposalForLoggedUser: null,
  proposalForAssignedUser: null,
  proposalsWaitingApproval: null,
  proposalDocs: null,
};

export const addEditProposal = createAsyncThunk(
  "proposal/addProposal",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Proposals/UpsertClientProposal", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        // dispatch(getProposalBasicInformation(response.data.insertedID));
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);
export const getProposalBasicInformation = createAsyncThunk(
  `proposal/getProposalBasicInformation`,
  async (id) => {
    return await axiosInstance
      .get(`Proposals/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const getServicesCategories = createAsyncThunk(
  `proposal/getServicesCategories`,
  async (id) => {
    return await axiosInstance
      .get(`Proposals/GetServicesCategoriesByProposalId?ProposalId=${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const addEditServices = createAsyncThunk(
  "proposal/addServices",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Proposals/InsertServices", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);

export const getQuestionsWithAnswers = createAsyncThunk(
  `proposal/getQuestionsWithAnswers`,
  async (id) => {
    return await axiosInstance
      .get(`Proposals/GetQuestionsWithAnswers/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const addQuestionsAnswers = createAsyncThunk(
  "proposal/addQuestionsAnswers",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post(
        `Proposals/AddQuestionsAnswers?projectId=${data.proposalId}`,
        data.values
      )
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);
export const getProposalSummary = createAsyncThunk(
  `proposal/getProposalSummary`,
  async (id) => {
    return await axiosInstance
      .get(`Proposals/GetProposalSummarybyID?projectId=${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

export const getProposalForLoginUser = createAsyncThunk(
  `proposal/getProposalForLoginUser`,
  async (id) => {
    return await axiosInstance
      .get(`Proposals/GetProposalForLoginUser`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const getProposalForAssignedToUser = createAsyncThunk(
  `proposal/getProposalForAssignedToUser`,
  async (id) => {
    return await axiosInstance
      .get(`Proposals/GetProposalForAssignedToUser`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const getWithWaitingApprovalProposals = createAsyncThunk(
  `proposal/getWithWaitingApprovalProposals`,
  async (id) => {
    return await axiosInstance
      .get(`Proposals/GetProposalWithWaitingApprovalStatus`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const sendProposalRequest = createAsyncThunk(
  "proposal/sendProposalRequest",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Proposals/SendProposalRequest", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);
export const uploadProposalDocuments = createAsyncThunk(
  "proposal/uploadProposalDocuments",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Proposals/UploadProposalDocuments", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);
export const getProposalDocuments = createAsyncThunk(
  "proposal/getProposalDocuments",
  async (id, { dispatch }) => {
    return await axiosInstance
      .get(`Proposals/GetProposalDocuments?ProposalId=${id}`)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);
export const updateProposalStatus = createAsyncThunk(
  "proposal/updateProposalStatus",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Proposals/UpdateProposalStatus", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);
export const getProjectByDealId = createAsyncThunk(
  "proposal/getProjectByDealId",
  async (id, { dispatch }) => {
    return await axiosInstance
      .get(`Proposals/GetProjectByDealId?dealId=${id}`)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);

// Proposals/GetProjectByDealId?dealId=08dd44e7-a037-4748-8941-9a2322fc883
const ProposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addEditProposal.fulfilled, (state, action) => {
      state.proposalId = action.payload.data.insertedID;
      localStorage.setItem("proposalId", action.payload.data.insertedID);
    });
    builder.addCase(getProposalBasicInformation.fulfilled, (state, action) => {
      state.basicInformation = action.payload.data;
    });
    builder.addCase(getServicesCategories.fulfilled, (state, action) => {
      state.services = action.payload.data;
    });
    builder.addCase(getQuestionsWithAnswers.fulfilled, (state, action) => {
      state.questionsAndAnswers = action.payload.data;
    });
    builder.addCase(getProposalSummary.fulfilled, (state, action) => {
      state.proposalSummary = action.payload.data;
    });
    builder.addCase(getProposalForLoginUser.fulfilled, (state, action) => {
      state.proposalForLoggedUser = action.payload.data;
    });
    builder.addCase(getProposalForAssignedToUser.fulfilled, (state, action) => {
      state.proposalForAssignedUser = action.payload.data;
    });
    builder.addCase(
      getWithWaitingApprovalProposals.fulfilled,
      (state, action) => {
        state.proposalsWaitingApproval = action.payload.data;
      }
    );
    builder.addCase(getProposalDocuments.fulfilled, (state, action) => {
      state.proposalDocs = action.payload.data;
    });
    builder.addCase(getProjectByDealId.fulfilled, (state, action) => {
      state.proposalId = action.payload.data.projectId;
      localStorage.setItem("proposalId", action.payload.data.projectId);
    });
  },
});

export const ProposalReducer = ProposalSlice.reducer;
